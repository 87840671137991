<template>
  <div class="grow flex flex-col">
    <div v-if="!sessionStore.isGuest" class="py-1 px-4 bg-warning">
      You are viewing the guests section. Go
      <NuxtLink :to="localeRoute({ name: 'events-eventId' })" class="link"
        >back</NuxtLink
      >
      to Instabooth management.
    </div>
    <div class="navbar bg-base-200 sticky top-0 z-10 shadow">
      <div class="container mx-auto px-4 flex justify-between items-center">
        <div class="space-x-2 flex justify-center gap-0.5">
          <NuxtLink
            v-if="
              sessionStore.isEventActive() &&
              !$route.name?.toString().includes('booths-eventId-capture')
            "
            :to="localeRoute('booths-eventId-guest-signin')"
            class="btn btn-primary btn-outline btn-sm btn-square ring-2">
            <ph-camera size="20" weight="duotone" />
          </NuxtLink>
          <NuxtLink
            v-if="!sessionStore.isEventInThePast"
            :to="localeRoute('booths-eventId-invite')"
            class="btn btn-primary btn-outline btn-sm btn-square ring-2">
            <ph-share-network size="20" weight="duotone" />
          </NuxtLink>
          <button
            class="btn btn-primary btn-outline btn-sm btn-square ring-2"
            @click="applyLocale">
            {{ locale === "en" ? "ΕΛ" : "EN" }}
          </button>
        </div>
        <GuestMenu />
      </div>
    </div>
    <div class="container mx-auto p-6 pt-8">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PhCamera, PhShareNetwork } from "@phosphor-icons/vue";
  const sessionStore = useSessionStore();
  const localeRoute = useLocaleRoute();
  const { locale, setLocale } = useI18n();

  const applyLocale = async () => {
    await setLocale(locale.value === "en" ? "el" : "en");
    location.reload();
  };
</script>
